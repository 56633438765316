<template>
    <el-scrollbar class="data-center" :class="role === '2' ? 'data-centers' : ''">
        <div class="data-content">
            <div class="filter-box" :class="role === '2' ? 'filter-boxs' : ''">
                <div class="filter-item">
                    <div class="icon-box">
                        <i class="iconfont">&#xe60f;</i>
                    </div>
                    <span class="item-name">{{school_name}}</span>
                </div>
                <div class="filter-item">
                    <div class="icon-box">
                        <i class="iconfont">&#xe69f;</i>
                    </div>
                    <span class="item-name">班级</span>
                </div>
                <el-select v-model="selectValue" clearable placeholder="请选择" class="data-select" @change="selectedClass">
                    <el-option
                            v-for="item in classList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="data-item">
                <div class="title">
                    <img src="../../../assets/images/student/data-center-2.png" alt="">
                    <span class="text">考试数据</span>
                </div>
                <div class="data-body">
                    <div class="box">
                        <!--电子商务师考试-->
                        <div class="box-item">
                            <div class="charts-top">
                                <div class="left-box">
                                    <span class="left-title">电子商务师考试</span>
                                </div>
                                <div class="right-box">
                                    <div class="right-item">
                                        <div class="item-icon green"></div>
                                        <div class="item-name">考试人数</div>
                                    </div>
                                    <div class="right-item">
                                        <div class="item-icon yellow"></div>
                                        <div class="item-name">获证人数</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top-exam" id="topExam" style="width: 100%;height:450px;"></div>
                        </div>
                        <!--考试合格率-->
                        <div class="box-item has-echart">
                            <div class="charts-top">
                                <div class="left-box">
                                    <span class="left-title">考试合格率</span>
                                </div>
                                <div class="right-box">
                                    <div class="right-item" v-if="is_exam_rate_level_three">
                                        <div class="item-icon green"></div>
                                        <div class="item-name">三级电子商务师</div>
                                    </div>
                                    <div class="right-item" v-if="is_exam_rate_level_four">
                                        <div class="item-icon cyan"></div>
                                        <div class="item-name">四级电子商务员</div>
                                    </div>
                                </div>
                            </div>
                            <div class="top-exam is-exam_rate" :class="is_exam_rate ? '' : 'is-no-data'" id="examRate" style="width: 100%;height:450px;"></div>
                            <div v-if="is_exam_rate === false" class="no-data-echart">暂无数据</div>
                        </div>
                        <!--技能分析-->
                        <div class="box-item has-echart">
                            <div class="charts-top">
                                <div class="left-box">
                                    <span class="left-title">技能分析</span>
                                </div>
                                <div class="right-box">
                                    <el-select v-model="selectValue1" placeholder="请选择" class="data-select" @change="selectedSkill">
                                        <el-option
                                                v-for="item in skillList"
                                                :key="item.parant_tag_id"
                                                :label="item.parant_tag_name"
                                                :value="item.parant_tag_id">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="top-exam" :class="noSkillData ? 'is-no-data' : ''" id="skillAnalysis" style="width: 100%;height:450px;padding: 20px;box-sizing: border-box;"></div>
                            <div v-if="skillList.length === 0" class="no-data-echart">暂无数据</div>
                        </div>
                    </div>
                    <div class="bottom-box">
                        <!--获证人数-->
                        <div class="box-item">
                            <div class="item-content">
                                <div class="top-content">
                                    <div class="top-title">获证人数</div>
                                    <div class="top-num">{{certificate_num.total_num}}</div>
                                </div>
                                <div class="middle-content">
                                    <div class="middle-left">
                                        <span class="first-title">月同比</span>
                                        <i class="el-icon-caret-bottom" v-if="certificate_num.yue_tong_type < 0"></i>
                                        <i class="el-icon-caret-top" v-if="certificate_num.yue_tong_type > 0"></i>
                                        <span>{{certificate_num.yue_tong}}</span>
                                    </div>
                                    <div class="middle-right">
                                        <span class="first-title">月环比</span>
                                        <i class="el-icon-caret-bottom" v-if="certificate_num.yue_huan_type < 0"></i>
                                        <i class="el-icon-caret-top" v-if="certificate_num.yue_huan_type > 0"></i>
                                        <span>{{certificate_num.yue_huan}}</span>
                                    </div>
                                </div>
                                <div class="bottom-content">
                                    <span class="bottom-left">月均获证人数</span>
                                    <span class="bottom-right">{{certificate_num.avg_month}}</span>
                                </div>
                            </div>
                        </div>
                        <!--考试次数-->
                        <div class="box-item">
                            <div class="item-content">
                                <div class="top-content">
                                    <div class="top-title">考试次数</div>
                                    <div class="top-num">{{exam_num.exam_total_num}}</div>
                                </div>
                                <div class="middle-content">
                                    <div class="top-exam" id="examNum" style="width: 100%;height:100%;"></div>
                                </div>
                                <div class="bottom-content">
                                    <span class="bottom-left">日均考试次数</span>
                                    <span class="bottom-right">{{exam_num.avg_month}}</span>
                                </div>
                            </div>
                        </div>
                        <!--考试合格率-->
                        <div class="box-item">
                            <div class="item-content">
                                <div class="top-content">
                                    <div class="top-title">考试合格率</div>
                                    <div class="top-num">{{qualified_rate.total}}</div>
                                </div>
                                <div class="lin-icon">
                                    <span class="top-icon"></span>
                                </div>

                                <el-progress :text-inside="true" :stroke-width="20" :percentage="qualified_rate.total" color="#18C1FF" stroke-linecap="square" class="small-rate" style="margin-top: 10px;"></el-progress>
                                <div class="lin-icon">
                                    <span class="top-icon"></span>
                                </div>
                                <div class="bottom-content">
                                    <div class="middle-left">
                                        <span class="first-title">月同比</span>
                                        <i class="el-icon-caret-bottom" v-if="certificate_num.yue_tong_type < 0"></i>
                                        <i class="el-icon-caret-top" v-if="certificate_num.yue_tong_type > 0"></i>
                                        <span>{{qualified_rate.yue_tong}}</span>
                                    </div>
                                    <div class="middle-right">
                                        <span class="first-title">月环比</span>
                                        <i class="el-icon-caret-bottom" v-if="certificate_num.yue_huan_type < 0"></i>
                                        <i class="el-icon-caret-top" v-if="certificate_num.yue_huan_type > 0"></i>
                                        <span>{{qualified_rate.yue_huan}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--训练次数-->
                        <div class="box-item">
                            <div class="item-content">
                                <div class="top-content">
                                    <div class="top-title">训练次数</div>
                                    <div class="top-num">{{train_num.total_num}}</div>
                                </div>
                                <div class="middle-content">
                                    <div class="top-exam" id="trainNum" style="width: 100%;height:100%;"></div>
                                </div>
                                <div class="bottom-content">
                                    <span class="bottom-left">日均训练次数</span>
                                    <span class="bottom-right">{{train_num.avg_day}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="data-item mock-exam-item">
                <div class="title">
                    <img src="../../../assets/images/student/data-center-3.png" alt="">
                    <span class="text">模拟考试</span>
                </div>
                <div class="data-body">
                    <div class="box">
                        <div class="left">
                            <!--考试人数-->
                            <div class="exam-num">
                                <div class="icon">
                                    <i class="iconfont">&#xe62a;</i>
                                </div>
                                <div class="num-show">
                                    <span class="text">考试人数：</span>
                                    <span class="num">{{mocks_data.total_num}}</span>
                                </div>
                            </div>
                            <!--合格人数-->
                            <div class="certified-num">
                                <div class="icon">
                                    <i class="iconfont">&#xe639;</i>
                                </div>
                                <div class="num-show">
                                    <span class="text">合格人数：</span>
                                    <span class="num">{{mocks_data.qualified_num}}</span>
                                </div>
                            </div>
                        </div>
                        <!--合格率-->
                        <div class="right">
                            <div class="pass-rate">合格率</div>
                            <el-progress type="circle" :percentage="mocks_data.qualified_rate" color="#2DC079" :width="202"
                                         :stroke-width="20"></el-progress>
                        </div>
                    </div>
                </div>
                <div style="background-color: #fff;margin-top: 20px;box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);">
                    <div class="charts-top">
                        <div class="left-box">
                            <span class="left-title">模拟考试数据</span>
                            <span class="right-title">近期20场模拟考试</span>
                        </div>
                        <div class="right-box">
                            <div class="right-item">
                                <div class="item-icon green"></div>
                                <div class="item-name">考试人数</div>
                            </div>
                            <div class="right-item">
                                <div class="item-icon yellow"></div>
                                <div class="item-name">合格率</div>
                            </div>
                        </div>
                    </div>
                    <div class="mock-exam" id="mockExam" style="width: 100%;height:450px;"></div>
                </div>
            </div>
            <div class="data-item">
                <div class="title">
                    <img src="../../../assets/images/student/data-center-1.png" alt="">
                    <span class="text">班级数据</span>
                </div>
                <div class="class-item" v-for="item in classData">
                    <span class="class-name">{{item.class_name}}</span>
                    <span class="class-top">TOP10</span>
                    <div class="class-table">
                        <div class="table-title">
                            <span class="title-item">学生名称</span>
                            <span class="title-item">训练次数</span>
                            <span class="title-item">模拟次数</span>
                            <span class="title-item">考试次数</span>
                            <span class="title-item">四级电子商务员证书</span>
                            <span class="title-item">三级电子商务师证书</span>
                        </div>
                        <div class="table-body" v-if="item.data">
                            <div class="body-item" v-for="itemStudent in item.list">
                                <div class="student-name title-item">
                                    <img :src="itemStudent.avatar" alt="">
                                    <span class="name">{{itemStudent.name}}</span>
                                </div>
                                <span class="text title-item">{{itemStudent.train_num}}</span>
                                <span class="text title-item">{{itemStudent.mocks_num}}</span>
                                <span class="text title-item">{{itemStudent.exam_num}}</span>
                                <i class="iconfont title-item pass" v-if="itemStudent.is_level_four === 1">&#xe656;</i>
                                <i class="iconfont title-item no-pass" v-if="itemStudent.is_level_four === 0">&#xe659;</i>
                                <i class="iconfont title-item pass" v-if="itemStudent.is_level_three === 1">&#xe656;</i>
                                <i class="iconfont title-item no-pass" v-if="itemStudent.is_level_three === 0">&#xe659;</i>
                            </div>
                        </div>
                    </div>
                    <div class="no-data" v-if="item.list.length === 0" style="width: 100%;text-align: center;height: 30px;margin-top: 20px;">暂无数据</div>
                    <div class="more" @click="viewMore(item.class_id)" v-if="item.list.length > 10">查看更多<i class="iconfont">&#xe602;</i></div>
                </div>
                <div class="no-data" v-if="classData.length === 0" style="width: 100%;text-align: center;height: 30px;margin-top: 20px;">暂无数据</div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { teacherdataClassExamDataAll } from '@/utils/apis.js'
    import echarts from "echarts";

    export default {
        name: "Index",
        data() {
            return {
                role: '',
                //学校名称
                school_name: '',
                //班级列表
                classList: [],
                selectValue: '',
                //电子商务师考试
                commerce_master_exam: {
                    level_four: {
                        certificate_num: null,
                        exam_num: null
                    }
                },
                //考试合格率
                exam_qualified_rate: {
                    level_four: 'null',
                    level_three: 'null'
                },
                is_exam_rate: false,
                is_exam_rate_level_three: false,
                is_exam_rate_level_four: false,
                //技能分析
                skillList: [],
                skillRate: [],
                skill_children_list: [],
                selectValue1: '',
                //是否有技能分析数据
                noSkillData: false,
                //获证人数
                certificate_num: {//获证人数
                    total_num: null,//总人数
                    yue_tong: null,//月同比
                    yue_huan: null,//月环比
                    avg_month: null,//月均人数
                    yue_tong_type: null,//月同类型  0小于零 ，1等于零  ，2大于零
                    yue_huan_type: null//月同类型  0小于零 ，1等于零  ，2大于零
                },
                //考试次数
                exam_num: {
                    exam_total_num: null,
                    avg_month: null,
                    data: []
                },
                //考试合格率
                qualified_rate: {
                    total: 0,
                    yue_tong: null,
                    yue_huan: null,
                    yue_tong_type: null,//月同类型  0小于零 ，1等于零  ，2大于零
                    yue_huan_type: null//月同类型  0小于零 ，1等于零  ，2大于零
                },
                //训练次数
                train_num: {
                    total_num: null,
                    avg_day: null,
                    data: [],
                },
                //模拟考试
                mocks_data: {
                    total_num: null,
                    qualified_num: null,
                    qualified_rate: 0,
                    class_data_20: []
                },
                //班级数据
                classData: [],
                //图表
                topExam: null,
                examRate: null,
                skillAnalysis: null,
                examNum: null,
                trainNum: null,
                mockExam: null,
            }
        },
        mounted() {
            this.getDataDetail();
        },
        created() {
            this.role = localStorage.getItem('role');
        },
        methods: {
            //获取数据中心详细信息
            async getDataDetail() {
                let param = {}
                if (this.selectValue) {
                    param.class_id = this.selectValue;
                }
                let res
                res = await teacherdataClassExamDataAll(param);
                //学校名称
                this.school_name = res.data.school_name;
                //班级列表
                this.classList = res.data.class_info;
                //电子商务师考试
                if (res.data.exam_data.commerce_master_exam) {
                    this.commerce_master_exam.level_four.certificate_num = res.data.exam_data.commerce_master_exam.level_four.certificate_num;
                    this.commerce_master_exam.level_four.exam_num = res.data.exam_data.commerce_master_exam.level_four.exam_num;
                    // this.setTopExam();
                } else {
                    this.commerce_master_exam.level_four.certificate_num = 0;
                    this.commerce_master_exam.level_four.exam_num = 0;
                    // this.setTopExam();
                }
                //考试合格率
                if (res.data.exam_data.exam_qualified_rate) {
                    this.is_exam_rate = true;
                    if (res.data.exam_data.exam_qualified_rate.level_three === 0 || res.data.exam_data.exam_qualified_rate.level_three > 0) {
                        this.exam_qualified_rate.level_three = res.data.exam_data.exam_qualified_rate.level_three;
                        this.is_exam_rate_level_three = true;
                    } else {
                        this.is_exam_rate_level_three = false;
                    }
                    if (res.data.exam_data.exam_qualified_rate.level_four === 0 || res.data.exam_data.exam_qualified_rate.level_four > 0) {
                        this.exam_qualified_rate.level_four = res.data.exam_data.exam_qualified_rate.level_four;
                        this.is_exam_rate_level_four = true;
                    } else {
                        this.is_exam_rate_level_four = false;
                    }
                    // this.setExamRate();
                } else {
                    this.is_exam_rate = false;
                }
                //技能分析
                if (res.data.exam_data.skill_analysis.length > 0) {
                    this.skillList = res.data.exam_data.skill_analysis;
                    if (!this.selectValue1) {
                        this.selectValue1 = res.data.exam_data.skill_analysis[0].parant_tag_id;
                    }
                    // this.getSkillValue();
                    this.noSkillData = false;
                } else {
                    this.noSkillData = true;
                }
                //获证人数
                this.certificate_num.total_num = res.data.exam_data.certificate_num.total_num;
                this.certificate_num.yue_tong = res.data.exam_data.certificate_num.yue_tong;
                this.certificate_num.yue_huan = res.data.exam_data.certificate_num.yue_huan;
                this.certificate_num.avg_month = res.data.exam_data.certificate_num.avg_month;
                this.certificate_num.yue_tong_type = res.data.exam_data.certificate_num.yue_tong_type;
                this.certificate_num.yue_huan_type = res.data.exam_data.certificate_num.yue_huan_type;
                //考试次数
                this.exam_num = {
                    exam_total_num: res.data.exam_data.exam_num.exam_total_num,
                    avg_month: res.data.exam_data.exam_num.avg_month,
                    data: res.data.exam_data.exam_num.data,
                }
                //考试合格率
                this.qualified_rate = {
                    total: res.data.exam_data.qualified_rate.total,
                    yue_tong: res.data.exam_data.qualified_rate.yue_tong,
                    yue_huan: res.data.exam_data.qualified_rate.yue_huan,
                    yue_tong_type: res.data.exam_data.qualified_rate.yue_tong_type,
                    yue_huan_type: res.data.exam_data.qualified_rate.yue_huan_type
                }
                //训练次数
                this.train_num = {
                    total_num: res.data.exam_data.train_num.total_num,
                    avg_day: res.data.exam_data.train_num.avg_day,
                    data: res.data.exam_data.train_num.data,
                }
                //模拟考试
                this.mocks_data = {
                    total_num: res.data.mocks_data.total_num,
                    qualified_num: res.data.mocks_data.qualified_num,
                    qualified_rate: res.data.mocks_data.qualified_rate,
                    class_data_20: res.data.mocks_data.class_data_20
                }
                //班级数据
                if (res.data.class_data.length > 0) {
                    this.classData = res.data.class_data;
                    this.classData.forEach(item => {
                        item.list = [];
                        if (item.data.length === 0) {

                        } else {
                            for (let i in item.data) {
                                item.list.push(item.data[i])
                            }
                        }
                    })
                } else {
                    this.classData = [];
                }
                //echarts自适应
                this.$nextTick(() => {
                    this.topExam = this.$echarts.init(document.getElementById('topExam'));
                    this.examRate = this.$echarts.init(document.getElementById('examRate'));
                    this.skillAnalysis = this.$echarts.init(document.getElementById('skillAnalysis'));
                    this.examNum = this.$echarts.init(document.getElementById('examNum'));
                    this.trainNum = this.$echarts.init(document.getElementById('trainNum'));
                    this.mockExam = this.$echarts.init(document.getElementById('mockExam'));

                    let resizeTimer = null;
                    window.onresize = () => {
                        clearTimeout(resizeTimer);
                        resizeTimer = setTimeout(() => {
                            this.topExam.resize();
                            this.examRate.resize();
                            this.skillAnalysis.resize();
                            this.examNum.resize();
                            this.trainNum.resize();
                            this.mockExam.resize();
                        }, 100)
                    }
                    this.setTopExam();
                    this.setExamRate();
                    if (this.skillList.length > 0) {
                        this.getSkillValue();
                        if (this.skill_children_list.length > 0) {
                            this.setSkillAnalysis();
                        }
                    }
                    if (this.exam_num.data) {
                        this.setExamNum();
                    }
                    if (this.train_num.data) {
                        this.setTrainNum();
                    }
                    this.setMockExam();
                })
            },
            //获取技能分析数据
            getSkillValue() {
                if (this.skillList.length > 0) {
                    let skill_children = [];
                    let skill_children_name = {};
                    skill_children = this.skillList;
                    skill_children.forEach(item => {
                        if (item.children.length > 0) {
                            item.children.forEach(itemChildren => {
                                if (itemChildren.parant_tag_id === this.selectValue1) {
                                    this.skillRate.push(itemChildren.rate);
                                    skill_children_name = {
                                        name: itemChildren.tag_name,
                                        max: 100
                                    }
                                    this.skill_children_list.push(skill_children_name);
                                }
                            })
                        }
                    })
                    if (this.skill_children_list.length > 0) {
                        this.setSkillAnalysis();
                    }
                }
            },
            //选择技能分析
            selectedSkill(val) {
                this.selectValue1 = val;
                this.skill_children_list = [];
                this.skillRate = [];
                this.getSkillValue();
            },
            //选择班级
            selectedClass(val) {
                this.selectValue = val;
                this.skill_children_list = [];
                this.skillRate = [];
                this.skillList = [];
                this.selectValue1 = '';
                this.getDataDetail();
            },
            //电子商务师考试
            setTopExam() {
                this.topExam.setOption(
                    {
                        color: ['#2DC079', '#F5AF23'],
                        tooltip: {
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top: '20%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                splitLine: {show: false}, //去除网格线
                                axisLine: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false,
                                },
                                data:['四级电子商务员考试']
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '考试人数',
                                axisLabel: { //y轴的坐标文字
                                    show: true,
                                    textStyle: {
                                        color: '#888' //文字的颜色
                                    },

                                },
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#888'
                                    }
                                },
                                axisTick: {
                                    show: false,
                                }
                            },
                        ],
                        // Declare several bar series, each will be mapped
                        // to a column of dataset.source by default.
                        series: [
                            {
                                type: 'bar',
                                barWidth: 30,
                                data: [this.commerce_master_exam.level_four.exam_num]
                            },
                            {
                                type: 'bar',
                                barWidth: 30,
                                data: [this.commerce_master_exam.level_four.certificate_num]
                            },
                        ]
                    }
                )
            },
            //考试合格率
            setExamRate() {
                let rate_data = [];
                let blank_rate_data = [];
                if (this.is_exam_rate_level_three) {
                    rate_data.push(this.exam_qualified_rate.level_three);
                    blank_rate_data.push((100 - this.exam_qualified_rate.level_three));
                }
                if (this.is_exam_rate_level_four) {
                    rate_data.push(this.exam_qualified_rate.level_four);
                    blank_rate_data.push((100 - this.exam_qualified_rate.level_four));
                }
                if (this.is_exam_rate_level_four && this.is_exam_rate_level_three) {
                    rate_data.push(this.exam_qualified_rate.level_three, this.exam_qualified_rate.level_four);
                    blank_rate_data.push((1100 - this.exam_qualified_rate.level_three), (100 - this.exam_qualified_rate.level_four));
                }
                this.examRate.setOption(
                    {
                        title: {
                            text: '合格率',
                            textStyle: {
                                color: '#333333',
                                fontSize: 16
                            },
                            left: 'center',
                            top: 'center'
                        },
                        tooltip: {
                            trigger: 'axis',
                            borderWidth: "0", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                            formatter(params) {
                                return '合格率:' + params[0].value + '%'
                            }
                        },
                        angleAxis: {
                            max: 100,
                            startAngle: 90,
                            show: false,
                            handle: {size: 50, show: true},
                            splitLine: {
                                show: false
                            }
                        },

                        grid: {width: '10'},
                        radiusAxis: {
                            type: 'category',
                            show: false,
                            splitLine: {
                                show: false
                            },
                            name: '111',
                            data: ['50%', '42%', 'x', 'y', 'z'],

                        },
                        polar: {
                            tooltip: {show: true},
                            radius: ['50%', '100%']
                        },
                        series: [{
                            type: 'bar',
                            stack: 'one',
                            data: rate_data,
                            roundCap: true,
                            zlevel: 2,
                            coordinateSystem: 'polar',
                            color: ['#2DC079', '#BAD80B'],
                            itemStyle: {
                                normal: {
                                    color: function (params) {
                                        let colorlist = ['#2DC079', '#BAD80B'];
                                        return colorlist[params.dataIndex];
                                    },
                                    label: {
                                        label: {
                                            show: true,      //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: {     //数值样式
                                                color: '#888888',
                                                fontSize: 14
                                            }
                                        }
                                    }
                                }
                            },
                            barCategoryGap: '0',
                            barWidth: 20,
                            labelLine: {    //引导线设置
                                normal: {
                                    show: true,   //引导线显示
                                }
                            },
                        }, {
                            type: 'bar',
                            stack: 'one',
                            roundCap: true,
                            zlevel: 1,
                            data: blank_rate_data,
                            coordinateSystem: 'polar',
                            color: '#0EA85E',
                            // barWidth:10,
                            itemStyle: {
                                color: '#E7F6EF'
                            }
                        }],
                    }
                )
            },
            //技能分析
            setSkillAnalysis() {
                this.skillAnalysis.setOption(
                    {
                        // 图表的位置
                        grid: {
                            position: 'center',
                        },
                        tooltip: {
                            trigger:'item',
                            //雷达图的tooltip不会超出div，也可以设置position属性，position定位的tooltip 不会随着鼠标移动而位置变化，不友好
                            confine: true,
                            enterable: true, //鼠标是否可以移动到tooltip区域内
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                        },
                        radar: {
                            shape: 'circle',
                            splitNumber: 4, // 雷达图圈数设置
                            name: {
                                textStyle: {
                                    color: '#444444',
                                    fontSize: 13,
                                },
                            },
                            // 设置雷达图中间射线的颜色
                            axisLine: {
                                lineStyle: {
                                    color: 'rgba(131,141,158,.1)',
                                },
                            },
                            indicator: this.skill_children_list,
                            //雷达图背景的颜色，在这儿随便设置了一个颜色，完全不透明度为0，就实现了透明背景
                            splitArea: {
                                show: true,
                                areaStyle: {
                                    color: '#F8F8F8', // 图表背景的颜色
                                },
                            },
                            splitLine: {
                                show: true,
                                lineStyle: {
                                    width: 1,
                                    color: 'rgba(131,141,158,.1)', // 设置网格的颜色
                                },
                            },
                        },
                        series: [{
                            type: 'radar', //表示是雷达图
                            symbol: 'circle', // 拐点的样式，还可以取值'rect','angle'等
                            smooth: true,
                            symbolSize: 0, // 拐点的大小

                            areaStyle: {
                                normal: {
                                    width: 1,
                                    // opacity: 0,
                                    color: '#ffffff8a' // 填充的颜色。[ default: "#000" ]
                                },
                            },
                            lineStyle: {
                                width: 4
                            },
                            data: [
                                {
                                    value: this.skillRate,
                                    name: '各项技能占比（%）：',
                                    // 设置区域边框和区域的颜色
                                    itemStyle: {
                                        normal: {
                                            color: new echarts.graphic.LinearGradient(
                                                0, 0, 0, 1,
                                                [
                                                    {offset: 0, color: '#2DC079'},
                                                    {offset: 0.5, color: '#BAD80B'},
                                                    {offset: 1, color: '#02C9D0'}
                                                ]
                                            ),
                                            lineStyle: {
                                                color: 'rgba(255,0,0,0)',
                                            },
                                        },
                                    },
                                    //在拐点处显示数值
                                    label: {
                                        normal: {
                                            show: true,
                                            formatter: (params) => {
                                                return params.value
                                            },
                                        },
                                    },
                                },
                            ],
                        }],
                    }
                )
            },
            //考试次数
            setExamNum() {
                let date = [];
                let data = [];
                let exam_num_data = [];
                for (let i in this.exam_num.data) {
                    exam_num_data.push(this.exam_num.data[i]);
                }

                date = Object.keys(this.exam_num.data);
                exam_num_data.forEach(item => {
                    data.push(item);
                })
                this.examNum.setOption(
                    {
                        tooltip: {
                            trigger: 'axis',
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: date,
                            show: false,
                            splitLine: {show: false}, //去除网格线
                        },
                        yAxis: {
                            type: 'value',
                            boundaryGap: [0, '100%'],
                            show: false,
                            splitLine: {show: false}, //去除网格线
                        },
                        dataZoom: [{
                            show: false,
                            type: 'inside',
                            start: 0,
                            end: 10
                        }],
                        series: [
                            {
                                name: '次数',
                                type: 'line',
                                symbol: 'none',
                                itemStyle: {
                                    color: '#F5AF23'
                                },
                                areaStyle: {
                                    color: '#F5AF23'
                                },
                                data: data
                            }
                        ]
                    }
                )
            },
            //训练次数
            setTrainNum() {
                let date = [];
                let data = [];
                let exam_num_data = [];
                for (let i in this.train_num.data) {
                    exam_num_data.push(this.train_num.data[i]);
                }

                date = Object.keys(this.train_num.data);
                exam_num_data.forEach(item => {
                    data.push(item);
                })
                this.trainNum.setOption(
                    {
                        color: ['#62D69F'],
                        tooltip: {
                            trigger: 'axis',
                            position: function (pt) {
                                return [pt[0], '10%'];
                            }
                        },
                        xAxis: {
                            type: 'category',
                            data: date,
                            show: false,
                            splitLine: {show: false}, //去除网格线
                        },
                        yAxis: {
                            type: 'value',
                            show: false,
                            splitLine: {show: false}, //去除网格线
                        },
                        series: [{
                            name: '次数',
                            data: data,
                            type: 'bar',
                            barWidth: 20,
                        }]
                    }
                )
            },
            //模拟考试图表
            setMockExam() {
                let name = [];
                let total_num = [];
                let qualified_rate = [];
                this.mocks_data.class_data_20.forEach(item => {
                    name.push(item.name);
                    total_num.push(item.total_num);
                    qualified_rate.push(item.qualified_rate);
                })
                //获取最大值
                let max = total_num[0]
                total_num.forEach(item => max = item > max ? item : max)
                this.mockExam.setOption(
                    {
                        color: ['#2DC079', '#F5AF23'],
                        tooltip: {
                            borderWidth: "1", //边框宽度设置1
                            borderColor: "#888",
                            backgroundColor: '#fff',
                            textStyle: {
                                color: "#666666" //设置文字颜色
                            },
                        },
                        grid: { //图表和父盒子之间的距离
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            top: '17%',
                            containLabel: true
                        },
                        xAxis: { //x轴
                            type: 'category',
                            data: name,
                            axisLabel: {
                                interval: 0,
                                textStyle: {
                                    color: '#333',
                                    fontSize: 14
                                },
                                margin: 8,
                                rotate: -38
                            },
                            splitLine: {show: false}, //去除网格线
                            axisLine: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            }
                        },
                        yAxis: [
                            {
                                type: 'value',
                                name: '考试人数(人)',
                                axisLabel: { //y轴的坐标文字
                                    show: true,
                                    textStyle: {
                                        color: '#888' //文字的颜色
                                    },

                                },
                                splitLine: {show: false}, //去除网格线
                                max: max,
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#888'
                                    }
                                },
                                axisTick: {
                                    show: false,
                                }
                            },
                            {
                                type: 'value',
                                name: '合格率(%)',
                                axisLabel: { //y轴的坐标文字
                                    show: true,
                                    textStyle: {
                                        color: '#888' //文字的颜色
                                    },

                                },
                                min: 0,
                                max: 100,
                                position: 'right',
                                axisLine: {
                                    show: false,
                                    lineStyle: {
                                        color: '#888'
                                    }
                                },
                                axisTick: {
                                    show: false,
                                }
                            },
                        ],
                        series: [
                            {
                                name: '考试人数(人)',
                                type: 'bar', //柱状图
                                data: total_num,
                                barWidth: '20px', //柱状体的宽度
                                barGap:'5%',//每根柱子之间的距离
                            },
                            {
                                name: '合格率(%)',
                                type: 'bar',
                                data: qualified_rate,
                                barWidth: '20px'
                            }
                        ]
                    }
                )
            },
            //查看更多学生数据
            viewMore(class_id) {
                if (this.role === '2') {
                    this.$router.push({
                        path: '/school/data/detail',
                        query: {
                            c_id: class_id
                        }
                    });
                } else {
                    this.$router.push({
                        path: '/dataCenter/detail',
                        query: {
                            c_id: class_id
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .data-center {
        /*position: fixed;*/
        top: -40px;
        bottom: 0;
        width: 100%;
        height: calc(100% + 40px);
        background-color: #F7F8FC;

        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }

    .data-content {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 0;
        height: 100%;
        background-color: #F7F8FC;
        margin: 0 auto;

        .filter-box {
            background: #ffffff;
            height: 80px;
            display: flex;
            align-items: center;

            .filter-item {
                margin-left: 32px;
                display: flex;
                align-items: center;

                .icon-box {
                    width: 40px;
                    height: 39px;
                    background: #E2F9FB;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    i {
                        font-size: 22px;
                    }
                }

                .item-name {
                    margin-left: 12px;
                    font-size: 16px;
                }

                &:nth-of-type(2) {
                    margin-left: 54px;
                }
            }
        }

        .data-select {
            width: 200px;
            margin-left: 15px;
        }

        .data-item {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            &:nth-of-type(2) {
                margin-top: 22px;
            }

            .title {
                position: relative;
                height: 50px;

                img {
                    width: 218px;
                    height: 50px;
                }

                .text {
                    position: absolute;
                    left: 0;
                    display: inline-block;
                    width: 218px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    font-size: 20px;
                    color: #fff;
                }
            }

            .charts-top {
                display: flex;
                justify-content: space-between;
                margin: 20px 20px 0;
                position: relative;

                .left-title {
                    font-size: 16px;
                }

                .right-title {
                    font-size: 16px;
                    border-left: 1px solid #333333;
                    margin-left: 10px;
                    padding-left: 10px;
                    color: #2DC079;
                }

                &:after {
                    position: absolute;
                    content: '';
                    bottom: -18px;
                    width: 100%;
                    height: 1px;
                    background: #EEEEEE;
                }

                .right-box {
                    display: flex;

                    .right-item {
                        display: flex;
                        align-items: center;

                        &:last-child {
                            margin-left: 20px;
                        }

                        .item-icon {
                            width: 14px;
                            height: 14px;
                            border-radius: 2px;
                            margin-right: 9px;

                            &.green {
                                background: #2DC079;
                            }

                            &.yellow {
                                background: #F5AF23;
                            }

                            &.cyan {
                                background: #BAD80B;
                            }
                        }

                        .item-name {
                            color: #666666;
                        }
                    }

                    .data-select {
                        height: 30px;

                        ::v-deep .el-input--suffix {
                            height: 30px;

                            .el-input__inner {
                                height: 30px;
                            }

                            .el-input__suffix {
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }

            .data-body {
                margin-top: 22px;

                .box {
                    display: flex;

                    .box-item {
                        background: #ffffff;
                        /*width: 33%;*/
                        margin-left: 25px;

                        &:first-child {
                            margin-left: 0;
                            min-width: calc((100% - 520px) /2 - 20px);
                        }
                        &:nth-child(2) {
                            min-width: 400px;
                        }
                        &:nth-child(3) {
                            min-width: calc((100% - 370px) /2 + 20px);
                        }
                    }

                    .left {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        width: 1%;
                        margin-right: 20px;

                        .exam-num {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 50%;
                            height: 100%;
                            background: #ffffff;
                            box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                            border-radius: 6px;
                            margin-right: 20px;
                            position: relative;

                            &:after {
                                position: absolute;
                                content: '';
                                height: 5px;
                                width: 100%;
                                background: #2DC079;
                                bottom: 0;
                            }

                            .icon {
                                width: 116px;
                                height: 116px;
                                line-height: 116px;
                                background: #2DC079;
                                border-radius: 50%;
                                text-align: center;
                                margin-right: 40px;

                                i {
                                    font-size: 60px;
                                    color: #fff;
                                }
                            }

                            .num-show {
                                display: flex;
                                flex-direction: column;

                                .text {
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #333333;
                                }

                                .num {
                                    font-size: 48px;
                                    font-weight: bold;
                                    color: #2DC079;
                                    font-family: Source Han Sans CN;
                                }
                            }
                        }

                        .certified-num {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 50%;
                            height: 100%;
                            background: #ffffff;
                            box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                            border-radius: 6px;
                            margin-right: 20px;
                            position: relative;

                            &:after {
                                position: absolute;
                                content: '';
                                height: 5px;
                                width: 100%;
                                background: #18C1FF;
                                bottom: 0;
                            }

                            .icon {
                                width: 116px;
                                height: 116px;
                                line-height: 116px;
                                background: #18C1FF;
                                border-radius: 50%;
                                text-align: center;
                                margin-right: 40px;

                                i {
                                    font-size: 60px;
                                    color: #fff;
                                }
                            }

                            .num-show {
                                display: flex;
                                flex-direction: column;

                                .text {
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #333333;
                                }

                                .num {
                                    font-size: 48px;
                                    font-weight: bold;
                                    color: #18C1FF;
                                    font-family: Source Han Sans CN;
                                }
                            }
                        }
                    }

                    .right {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 452px;
                        height: 281px;
                        background-color: #fff;
                        box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
                        border-radius: 6px;

                        .pass-rate {
                            display: inline-block;
                            position: absolute;
                            left: 198px;
                            top: 157px;
                            font-size: 18px;
                            color: #333;
                        }
                    }
                }

                .bottom-box {
                    display: flex;
                    margin-top: 23px;

                    .box-item {
                        background: #ffffff;
                        width: 25%;
                        margin-left: 19px;
                        border-radius: 6px;
                        box-shadow: 0px 0px 10px 0px rgba(14, 168, 94, 0.1);

                        &:first-child {
                            margin-left: 0;
                        }

                        .item-content {
                            padding: 20px;

                            .top-content {
                                .top-title {
                                    color: #666666;
                                }

                                .top-num {
                                    margin-top: 20px;
                                    font-size: 36px;
                                    color: #000000;
                                }
                            }

                            .middle-content {
                                position: relative;
                                margin-top: 20px;
                                height: 40px;
                                display: flex;
                                align-items: center;

                                &:after {
                                    content: '';
                                    position: absolute;
                                    height: 1px;
                                    width: 100%;
                                    bottom: -24px;
                                    background: #EEEEEE;
                                }
                            }

                            .first-title {
                                margin-right: 9px;
                            }

                            i {
                                margin-right: 9px;
                                font-size: 18px;
                            }

                            .middle-left {
                                i {
                                    color: #2DC079;
                                }
                            }

                            .middle-right {
                                margin-left: 70px;

                                i {
                                    color: #FF5500;
                                }
                            }

                            .bottom-content {
                                margin-top: 43px;
                                display: flex;
                                align-items: center;

                                .bottom-right {
                                    margin-left: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .class-item {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0 0 10px 0 rgba(14, 168, 94, 0.1);
            border-radius: 6px;
            margin-top: 20px;
            padding: 40px 30px 20px 20px;
            box-sizing: border-box;

            .class-name {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
            }

            .class-top {
                font-size: 18px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
                margin-top: 28px;
            }

            .class-table {
                margin-top: 10px;

                .table-title {
                    height: 51px;
                    line-height: 51px;
                    background: #E7F6EF;
                    padding: 0 70px;
                }

                .title-item {
                    display: inline-block;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    color: #2DC079;
                    text-align: center;

                    &:nth-child(1) {
                        width: 40%;
                        text-align: left;
                    }

                    &:nth-child(2) {
                        width: 10%;
                    }

                    &:nth-child(3) {
                        width: 10%;
                    }

                    &:nth-child(4) {
                        width: 10%;
                    }

                    &:nth-child(5) {
                        width: 15%;
                    }

                    &:nth-child(6) {
                        width: 15%;
                    }
                }

                .table-body {
                    display: flex;
                    flex-direction: column;

                    .body-item {
                        display: flex;
                        align-items: center;
                        height: 90px;
                        padding: 0 70px;
                        border-bottom: 1px solid #EEEEEE;

                        .title-item {
                            color: #333;
                            font-weight: 400;
                        }
                        .no-pass {
                            color: #FF0000;
                            font-size: 30px;
                        }
                        .pass {
                            color: #2DC079;
                            font-size: 30px;
                        }
                        .student-name {
                            display: flex;
                            align-items: center;

                            img {
                                width: 42px;
                                height: 42px;
                                border-radius: 50%;
                                margin-right: 20px;
                            }
                        }
                    }
                }
            }

            .more {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #2DC079;
                margin-top: 27px;
                cursor: pointer;

                i {
                    font-size: 20px;
                }
            }
        }
    }
    .small-rate {
        ::v-deep .el-progress-bar__outer {
            border-radius: 0;
            .el-progress-bar__inner {
                border-radius: 0;
                .el-progress-bar__innerText {
                    display: none;
                }
            }
        }
    }
    .lin-icon {
        width: 100%;
        height: 10px;
        margin-left: 79%;
        .top-icon {
            display: inline-block;
            height: 10px;
            width: 3px;
            background-color: #18C1FF;
        }
    }
    .filter-boxs {
        margin-top: 20px;
    }
    .is-exam_rate {
        width: 400px !important;
    }
    .has-echart {
        display: flex;
        flex-direction: column;
    }
    .no-data-echart {
        flex: 1;
        height: 1%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .is-no-data {
        display: none;
    }
</style>